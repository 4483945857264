import React, { useRef, useState } from "react"
import { Formik } from "formik"
import * as Yup from "yup"
import styled from "styled-components"
import SignaturePad from "react-signature-canvas"
import { navigate } from "gatsby"

import { standardRequiredText } from "../constants"
import { submitForm, FormatFormData } from "../services/index"

import {
  Col2,
  Content,
  FieldSet,
  HeaderWithLogo,
  LCard,
} from "../style/containers"
import LearLogo from "../assets/images/logos/lear-corporation-logo.jpg"

import Button from "../components/button"
import Layout from "../components/layout"
import Form from "../components/form/FormHelper"
import { FormikInput, Label, Error } from "../components/form"

const SButton = styled(Button)`
  background-color: ${p => p.theme.colors.accent};
  margin-top: 10px;
  font-size: 10px;
  padding: 6px 12px;
`

const IntroductionForm = () => {
  const sigCanvas = useRef({})
  const [signatureDrawn, setSignatureDrawn] = useState()
  const [errorText, setErrorText] = useState([])

  const clear = () => (sigCanvas.current.clear(), setSignatureDrawn(false))

  const submit = async (values, actions) => {
    if (signatureDrawn) {
      const formatData = {...values}
      const formData = await FormatFormData(formatData, sigCanvas)
      let res = await submitForm(formData, "/lear-induction")
      handleSignupResponse(res)
    } else {
      setErrorText([
        "You must sign a signature before submitting. If you've already added a signature please clear and sign again",
      ])
    }
    actions.setSubmitting(false)
  }

  const handleSignupResponse = res => {
    if (res.error) {
      let error = Object.values(res.error)
      setErrorText(error)
    } else {
      navigate("/form-confirm")
    }
  }

  return (
    <Layout emailNoCheck={true}>
      <LCard>
        <FieldSet>
          <HeaderWithLogo>
            <h1>Lear Foam &amp; JIT Plant New Starter Form</h1>
            <img src={LearLogo} alt="Lear Logo" />
          </HeaderWithLogo>
          <p>
            The following list although not exhaustive may give an indication
            that an employee is under the influence of alcohol and drugs.
          </p>
          <p>Signs of current intoxication:</p>
          <ul>
            <li>Smell of alcohol</li>
            <li>Unsteadiness</li>
            <li>Mood swings including aggressive behavior</li>
            <li>Appearing to be unaware or detached from reality</li>
            <li>Incoherent speech or slurred words</li>
            <li>Poor communication and inappropriate comments to colleagues</li>
            <li>Anxious and irritable</li>
            <li>Glazed eyes</li>
          </ul>
          <p>Signs of current intoxication:</p>
          <ul>
            <li>Poor attendance and timekeeping</li>
            <li>Erratic period of absence with no apparent underlying cause</li>
            <li>Poor work performance</li>
            <li>Accident prone</li>
            <li>Overall presentation of employee is deteriorating</li>
            <li>Lack of concentration</li>
            <li>Argumentative and irritable </li>
            <li>Dramatic changes in a previously reliable employee</li>
          </ul>
          <Content>
            <hr />
          </Content>
          <Col2>
            <div>
              <Label>Document created/issued</Label>
              <p>4th March 2013</p>
            </div>
            <div>
              <Label>Created by</Label>
              <p>S Peart</p>
            </div>
            <div>
              <Label>Document revised</Label>
              <p>19th September 2019</p>
            </div>
            <div>
              <Label>Revised by</Label>
              <p>S Peart</p>
            </div>
            <div>
              <Label>Issued to</Label>
              <p>All employees and temporary workers</p>
            </div>
            <div>
              <Label>To be communicated with</Label>
              <p>All employees and temporary workers</p>
            </div>
          </Col2>
          <Content>
            <hr />
          </Content>
          <p style={{ fontWeight: "bold", textDecoration: "underline" }}>
            I have received Induction Training which included the following
            topics:
          </p>
          <Col2>
            <p>Company Information</p>
            <p>Basic Plant Rules</p>
            <p>Dignity at Work and Unacceptable Behaviours</p>
            <p>Fire Evacuation</p>
            <p>PPE and Jewellery</p>
            <p>Manual Handling Information</p>
            <p>Your health, Safety and Environmental Responsibilities</p>
            <p>Occupational Health </p>
            <p>Understanding COSHH</p>
            <p>Environmental Information</p>
            <p>Site HSE Policy</p>
            <p>Noise at Work</p>
            <p>What is MDI</p>
            <p>Drugs and Alcohol Policy (Full Policy)</p>
          </Col2>
          <Content>
            <hr />
          </Content>
          <Formik
            initialValues={{
              consultant_name: "",
              name: "",
              email: "",
            }}
            validationSchema={Yup.object().shape({
              consultant_name: Yup.string().required(standardRequiredText),
              name: Yup.string().required(standardRequiredText),
              email: Yup.string()
                .email(
                  "Must be a valid email address in the format 'example@example.com'"
                )
                .required(standardRequiredText),
            })}
            onSubmit={submit}
          >
            {({ isSubmitting }) => (
              <Form>
                <Col2>
                  <FormikInput
                    name="consultant_name"
                    type="text"
                    label="Consultant name"
                  />
                </Col2>
                <Col2>
                  <FormikInput name="name" type="text" label="Full name" />
                  <FormikInput name="email" type="email" label="Email" />
                </Col2>
                <div>
                  <Label>Signature</Label>
                  <SignaturePad
                    penColor="black"
                    backgroundColor="rgba(246,248,251,100)"
                    name="signature"
                    ref={sigCanvas}
                    canvasProps={{
                      className: "sigCanvas",
                    }}
                    onBegin={() =>
                      signatureDrawn ? null : setSignatureDrawn(true)
                    }
                  />

                  <Label>
                    You must sign before continuing, by signing this form you
                    are agreeing that you have received introduction training
                  </Label>
                  <SButton type="button" name="clear-signature" onClick={clear}>
                    Clear Signature
                  </SButton>
                </div>
                {errorText
                  ? errorText.map(e => (
                      <Error style={{ marginTop: "1rem" }} key={e}>
                        {e}
                      </Error>
                    ))
                  : null}
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  name="new-starter"
                >
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
        </FieldSet>
      </LCard>
    </Layout>
  )
}

export default IntroductionForm
